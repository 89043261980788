module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#f5f5f5',

  text: '#333333',

  primary: '#27496d',
  primaryLight: '#fc5185',
  primaryDark: '#364f6b',

  secondary: '#fc5185',
  secondaryLight: '#3fc1c9',
  secondaryDark: '#fc5185',

  // primary: 'e8505b',
  // primaryLight: '#ae66df',
  // primaryDark: '#4b007d',

  // secondary: '#ff4081',
  // secondaryLight: '#ff79b0',
  // secondaryDark: '#c60055',
};
